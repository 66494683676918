import { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import depositTabImg from "../../assets/images/depositTab.svg";
import withdrwaTabImg from "../../assets/images/withdrwaTab.svg";
import Decimal from "decimal.js";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import masterChefPoly from "../../abi/out/masterChefPolygon.sol/MasterChefPolygon.json";
import erc20Json from "../../abi/out/ERC20.sol/ERC20.json";
import riveraAutoCompoundingVaultV2WhitelistedJson from "../../abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import Web3 from "web3";
import { Toast } from "primereact/toast";
import { ethers } from "ethers";
import waitingImg from "../../assets/images/waiting.gif";
import successfullImg from "../../assets/images/successfull.gif";

interface ManageBtnProps {
  vaultName: string;
  Img1: string;
  Img2: string;
  addressNew: string;
  symbol: string;
  rpc: string;
  denominationDecimal: number;
  denominationAssetAddress: string;
  totalAssets: string;
  totalSupply: string;
  myBalance?: number;
  myBalanceNonDollar?: number;
  mystake?: number;
}

function ManageBtnPoly({
  vaultName,
  Img1,
  rpc,
  Img2,
  addressNew,
  symbol,
  denominationDecimal,
  myBalance,
  mystake,
  myBalanceNonDollar,
  denominationAssetAddress,
  totalAssets,
  totalSupply,
}: ManageBtnProps) {
  const [visible, setVisible] = useState(false);
  const [maxLimit, setMaxLimit] = useState<any>(null);
  const [depositAmout, setdepositAmout] = useState<any>("0");
  const [isApproved, setisApproved] = useState(false);
  const [withdrawAmout, setwithdrawAmout] = useState<any>("0");
  // const address = "0x0Cf6A0e59c6D02EA6Ae1C62FbAD91C49a00262b2";
  const { isConnected } = useAccount();
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const addDDD = "0x2c869eE61377290bbBE145b4f077113f2628Ce67";
  const [vaultNumber, setVaultNumber] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [approvalSuccessfullVisible, setapprovalSuccessfullVisible] =
    useState<boolean>(false);
  const [approvalWaitingVisible, setapprovalWaitingVisible] =
    useState<boolean>(false);

  const [openMetamaskVisible, setOpenMetamaskVisible] =
    useState<boolean>(false);
  const [depositWaitingVisible, setdepositWaitingVisible] =
    useState<boolean>(false);
  const [depositSuccessfullVisible, setdepositSuccessfullVisible] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);
  useEffect(() => {
    setLoading(true);
    setIsDataLoadng(true);
  }, [address]);

  useEffect(() => {
    setVaultNumber(0);
  }, [symbol]);
  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const getContract = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };

  const powfactor = Math.pow(10, denominationDecimal);
  const approveAllowance = async () => {
    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }
      setIsTransactionOnGoing(true);
      const aa = Decimal.pow(10, denominationDecimal);
      const bb = new Decimal(depositAmout);
      const convertedAmount = bb.mul(aa).toFixed();

      const erc20Contract = new ethers.Contract(
        addressNew,
        erc20Json.abi,
        signer
      );

      let gasEstimate;
      try {
        gasEstimate = await erc20Contract.estimateGas.approve(
          addDDD,
          convertedAmount
        );
      } catch (error) {
        gasEstimate = 300000; // Fallback gas limit
      }

      const tx = await erc20Contract.approve(addDDD, convertedAmount, {
        gasLimit: gasEstimate,
      });
      setapprovalWaitingVisible(true);

      await tx
        .wait()
        .then(async (e: any) => {
          setapprovalWaitingVisible(false);
          setapprovalSuccessfullVisible(true);
        })
        .catch((error: any) => {
          console.error("Error approving allowance:", error);
          showError("Error approving allowance: " + error);
        });
      showSuccess("Approval successful!");
      return true;
    } catch (error) {
      console.error("Error approving allowance:", error);
      showError("Error approving allowance: ");
      return false;
    } finally {
      setIsTransactionOnGoing(false);
    }
  };

  const checkAllowance = async () => {
    try {
      let localProvider;
      let vaultContract;
      const web3 = new Web3(rpc);
      localProvider = web3.eth.currentProvider;
      vaultContract = getContract(
        addressNew as string,
        riveraAutoCompoundingVaultV2WhitelistedJson.abi,
        localProvider
      );

      const valutAssetAdress = (await vaultContract.methods
        .asset()
        .call()) as any;

      const erc20Contract = getContract(
        valutAssetAdress,
        erc20Json.abi,
        localProvider
      );
      const allowance = (await erc20Contract.methods
        .allowance(address, addressNew)
        .call()) as any;

      setMaxLimit(ethers.utils.formatUnits(allowance, 18));
      const maxLimitVal = new Decimal(ethers.utils.formatUnits(allowance, 18));
      const despositAmtVal = new Decimal(depositAmout);

      if (despositAmtVal.gt(maxLimitVal)) {
        setisApproved(false);
        console.log("Not enough allowance. Please approve the contract first.");
        return false;
      } else {
        setisApproved(true);
        return true;
      }
    } catch (error) {
      console.error("Error checking allowance:", error);
      showError("Error checking allowance: " + error);
      return false;
    }
  };

  const handledepositAmoutChange = (event: any) => {
    let val = event.target.value;
    setdepositAmout(event.target.value);
  };
  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  };

  const handlewithdrawAmoutChange = (event: any) => {
    setwithdrawAmout(event.target.value);
  };

  interface CustomTabHeaderProps {
    icon: string;
    title: string;
  }

  const CustomTabHeader: React.FC<CustomTabHeaderProps> = ({ icon, title }) => {
    return (
      <>
        {icon && (
          <img
            src={icon}
            alt="Icon"
            style={{ marginRight: "10px", width: "28px" }}
          />
        )}
        <span>{title}</span>
      </>
    );
  };

  const withdrawAmnt = async () => {
    setTransactionType("Withdraw");
    const aa = Decimal.pow(10, denominationDecimal);
    const bb = new Decimal(withdrawAmout);
    const convertedAmount = bb.mul(aa).toFixed();
    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }
      if (isNaN(Number(convertedAmount)) || Number(convertedAmount) <= 0) {
        showError("Invalid amount. Please try again.");
        return;
      }

      const withdrawAmount = Number(withdrawAmout);
      const mystk = Number(mystake);
      if (withdrawAmount > mystk) {
        showError("Insufficient balance. Please try again.");
        return;
      }
      setIsTransactionOnGoing(true);
      const vaultContract = new ethers.Contract(
        addDDD,
        masterChefPoly.abi,
        signer
      );
      setdepositWaitingVisible(true);
      const withDraw = await vaultContract.withdraw(
        vaultNumber,
        convertedAmount,
        { gasLimit: 300000 }
      );

      await withDraw
        .wait()
        .then((e: any) => {
          setdepositWaitingVisible(false);
          setdepositSuccessfullVisible(true);
          setIsTransactionOnGoing(false);
        })
        .catch((error: any) => {
          setIsTransactionOnGoing(false);
          showError("Something went wrong");
          console.error("Error withdrawing:", error);
        });
      showSuccess("Withdrawal successful!");
      window.location.reload(); // Refresh the page
    } catch (error) {
      setIsTransactionOnGoing(false);
      console.error("Error withdrawing:", error);
      showError("Error withdrawing");
    } finally {
      setIsTransactionOnGoing(false);
      setdepositWaitingVisible(false);
    }
  };

  const depositAmnt = async () => {
    setTransactionType("Deposit");
    const aa = Decimal.pow(10, denominationDecimal);
    const bb = new Decimal(depositAmout);
    const convertedAmount = bb.mul(aa).toFixed();
    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }
      if (isNaN(Number(convertedAmount)) || Number(convertedAmount) <= 0) {
        showError("Invalid amount. Please try again.");
        return;
      }
      const depositAmount = Number(depositAmout);
      const myBal = Number(myBalance);
      const myBalNonDollar = Number(myBalanceNonDollar);
      if (depositAmount > Number(myBalNonDollar)) {
        showError("Insufficient balance. Please try again.");
        return;
      }
      setIsTransactionOnGoing(true);
      // Approve the allowance and check if it is successful
      const approvalSuccess = await approveAllowance();
      if (!approvalSuccess) {
        showError("Approval failed. Deposit cannot proceed.");
        return;
      }
      const vaultContract = new ethers.Contract(
        addDDD,
        masterChefPoly.abi,
        signer
      );
      const tx = await vaultContract.deposit(vaultNumber, convertedAmount, {
        gasLimit: 300000,
      });

      setapprovalSuccessfullVisible(false);
      setdepositWaitingVisible(true);

      await tx
        .wait()
        .then((e: any) => {
          setdepositWaitingVisible(false);
          setdepositSuccessfullVisible(true);
          setIsTransactionOnGoing(false);
        })
        .catch((error: any) => {
          setIsTransactionOnGoing(false);
          showError("Something went wrong");
          console.error("Error deposit", error);
        });
      showSuccess("Deposit successful!");
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error during deposit:", error);
      showError("Error during deposit ");
    } finally {
      setIsTransactionOnGoing(false);
      setdepositWaitingVisible(false);
    }
  };
  const updateDepositMax = () => {
    if (myBalanceNonDollar !== undefined && !isNaN(myBalanceNonDollar)) {
      try {
        const myBalanceDecimal = new Decimal(myBalanceNonDollar);
        const powfactorDecimal = new Decimal(powfactor);

        const fixedBalance = myBalanceDecimal
          .times(powfactorDecimal)
          .floor()
          .div(powfactorDecimal)
          .toFixed(denominationDecimal);

        setdepositAmout(fixedBalance);
      } catch (error) {
        console.error("Error in calculation:", error);
        showError(
          "Invalid balance amount or calculation error. Please try again."
        );
      }
    } else {
      console.error("Invalid myBalanceNonDollar value:", myBalanceNonDollar);
      showError("Invalid balance amount. Please try again.");
    }
  };

  const updateWithdrawMax = () => {
    if (mystake !== undefined && !isNaN(mystake)) {
      try {
        const mystakeDecimal = new Decimal(mystake);
        const powfactorDecimal = new Decimal(powfactor);

        const fixedBalance = mystakeDecimal
          .times(powfactorDecimal)
          .floor()
          .div(powfactorDecimal)
          .toFixed(denominationDecimal);

        setwithdrawAmout(fixedBalance);
      } catch (error) {
        console.error("Error in calculation:", error);
        showError(
          "Invalid stake amount or calculation error. Please try again."
        );
      }
    } else {
      console.error("Invalid mystake value:", mystake);
      showError("Invalid stake amount. Please try again.");
    }
  };
  // const updateDepositMax = () => {
  //   if (myBalanceNonDollar !== undefined && !isNaN(myBalanceNonDollar)) {
  //     const fixedBalance = (Math.floor(myBalanceNonDollar * powfactor) / powfactor).toFixed(denominationDecimal)
  //     setdepositAmout(fixedBalance);
  //   } else {
  //     console.error("Invalid myBalanceNonDollar value:", myBalanceNonDollar);
  //     showError("Invalid stake amount. Please try again.");
  //   }
  // };
  // const updateWithdrawMax = () => {
  //   if (mystake !== undefined && !isNaN(mystake)) {
  //     const fixedBalance = (Math.floor(mystake * powfactor) / powfactor).toFixed(denominationDecimal)
  //     setwithdrawAmout(fixedBalance);
  //   } else {
  //     console.error("Invalid mystake value:", mystake);
  //     showError("Invalid stake amount. Please try again.");
  //   }
  // };
  const switchNetwork = useSwitchNetwork();
  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };

  return (
    <>
      <Toast ref={toast} />

      <button
        className="mt-05 btn-riv-manage whitespace-nowrap"
        type="button"
        onClick={showDialog}
      >
        Manage
      </button>
      <Dialog
        visible={visible}
        className="custom-dialog"
        onHide={hideDialog}
        modal
        header={`Manage ${vaultName}`}
      >
        <div className="second_section outer_section_detail pos_sticky ">
          <TabView>
            <TabPanel
              header={<CustomTabHeader icon={depositTabImg} title="Stake" />}
            >
              <div className="mt-3">
                <div className="dsp mb-3">
                  <div className="fnt_wgt_800">Amount</div>
                  <button
                    className="btn mxBtn my-div"
                    onClick={updateDepositMax}
                  >
                    Max
                  </button>
                </div>
                <div className="dsp backGrd mb-3 border2 ">
                  <div>
                    <input
                      className="input_box_details wdth_200_px"
                      type="number"
                      id="first_name"
                      name="first_name"
                      value={depositAmout}
                      onChange={handledepositAmoutChange}
                    />
                  </div>
                  <div className="header_font_size2 redHatFont">
                    <span>
                      <img
                        src={Img1}
                        alt="btc img"
                        className="btc_asst_img_width"
                      />
                    </span>
                    <span>
                      <img
                        src={Img2}
                        alt="btc img"
                        className="btc_img_width2"
                      />
                    </span>
                    {vaultName}
                  </div>
                </div>
                <div className="dsp">
                  <div className="clr_prpl redHatFont fnt_wgt_600">
                    {myBalanceNonDollar !== undefined
                      ? (Math.floor(myBalanceNonDollar * 100) / 100).toFixed(2)
                      : "0.00"}
                  </div>
                  <div className="buy_cake ">
                    {" "}
                    <a rel="noreferrer" target="_blank" className="clr_prpl">
                      {symbol}
                    </a>
                  </div>
                </div>
                <div>
                  <div className="flexFoot">
                    <button
                      className="btn mt-05 btn-riv-primary whitespace-nowrap"
                      type="button"
                      onClick={depositAmnt}
                    >
                      {" "}
                      Stake
                    </button>
                    <button
                      className="btn mt-05 btn-riv-primary-cancel whitespace-nowrap"
                      type="button"
                      onClick={hideDialog}
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              header={<CustomTabHeader icon={withdrwaTabImg} title="Unstake" />}
            >
              <div className="mt-3 ">
                <div></div>
                <div className="dsp mb-3">
                  <div className="fnt_wgt_800">Amount</div>
                  <button
                    className="btn mxBtn my-div"
                    onClick={updateWithdrawMax}
                  >
                    {" "}
                    Max
                  </button>
                </div>
                <div className="dsp backGrd mb-3 ">
                  <div>
                    <input
                      className="input_box_details wdth_200_px"
                      type="number"
                      id="first_name_2"
                      name="first_name_2"
                      value={withdrawAmout}
                      onChange={handlewithdrawAmoutChange}
                    />
                  </div>
                  <div className="margStake header_font_size2 redHatFont">
                    <span>
                      <img
                        src={Img1}
                        alt="btc img"
                        className="btc_asst_img_width"
                      />
                    </span>
                    <span>
                      <img
                        src={Img2}
                        alt="btc img"
                        className="btc_img_width2"
                      />
                    </span>
                    {vaultName}
                  </div>
                </div>
                <div className="dsp">
                  <div></div>
                  <div className="buy_cake">
                    {" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="clr_prpl"
                    ></a>
                  </div>
                </div>
                <div className="flexFoot">
                  <button
                    className="btn mt-05 btn-riv-primary whitespace-nowrap"
                    type="button"
                    onClick={withdrawAmnt}
                  >
                    {" "}
                    UnStake
                  </button>
                  <button
                    className="btn mt-05 btn-riv-primary-cancel whitespace-nowrap"
                    type="button"
                    onClick={hideDialog}
                  >
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
      <Dialog
        visible={approvalWaitingVisible}
        className="dialogWdth"
        onHide={() => setapprovalWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for Approval</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={approvalSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setapprovalSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Approval Successful</div>
          <div>
            Opening Metamask, please confirm transaction in your wallet.
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openMetamaskVisible}
        className="dialogWdth"
        onHide={() => setOpenMetamaskVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Opening Metamask</div>
          <div>Please confirm transaction in your wallet.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositWaitingVisible}
        className="dialogWdth"
        onHide={() => setdepositWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for {transactionType}</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={depositSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setdepositSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">{transactionType} Successful</div>
          {transactionType === "Withdraw" ? (
            <></>
          ) : (
            <div>Great! You are invested now.</div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default ManageBtnPoly;
